<template>
  <div class="index clear">
    <div class="wrap" v-if="windowWidth">
      <div class="introduce">
        <p class="title">
          <span class="center">关于ARC</span>
        </p>
        <p class="content">
          <span
            >腾讯ARC Lab被称为腾讯 PCG
            的「侦察兵」、「特种兵」，站在腾讯探索挑战智能媒体相关前沿技术的第一线</span
          >
        </p>
      </div>
      <div class="hope">
        <div class="hope_left">
          <p class="title">
            <span>使命愿景</span>
          </p>
          <p class="content">
            <span
              >ARC（Applied Research
              Center）肩负着探索和挑战智能媒体相关前沿技术的使命，旨在成为世界一流应用研究中心和行业的标杆。所属的腾讯PCG是一个集社交、流量和内容平台于一体的大型事业群，业务需求覆盖了人工智能和大数据方面几乎所有的技术。ARC因此在研究领域的广度、深度和科研产业一体化方面有着得天独厚的优势。</span
            >
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
        <div class="hope_right">
          <img src="../../../assets/img/hope_right.png" alt="" />
        </div>
      </div>
      <div class="team">
        <div class="team_left">
          <img src="../../../assets/img/team_left.png" alt="" />
        </div>
        <div class="team_right">
          <p class="title">
            <span>团队介绍</span>
          </p>
          <p class="content">
            <span
              >自2019年初成立以来，ARC已吸引了一批高端人才加入。主要成员均来自国内外一流高校、有过国际大公司经历、发表过顶级学术文章、并持有多项国际专利。通过高校合作、假期实习、犀牛鸟计划等渠道，ARC也汇聚了一批来自国内外的优秀实习生。在人才招聘和培养方面，ARC的宗旨是找最好的人，做顶天（世界领先）立地（应用落地）的科研。
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
      <div class="field">
        <div class="field_left">
          <p class="title">
            <span>机制和领域</span>
          </p>
          <p class="content">
            <span
              >ARC通过和业务团队的协作，形成了一套解耦业务需求和科研的机制，既减少了沟通成本又提高了技术落地的保障和效率。ARC项目涉及的技术以视觉、音频和自然语言处理为主，在音视频内容的生成、增强、检索和理解等方向发力，通过AutoML、多模态和深度学习等方法提高算法性能。项目导向兼顾业务需求和行业趋势，鼓励在技术上不断探索、创新和突破。</span
            >
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
        <div class="field_right">
          <img src="../../../assets/img/field_right.png" alt="" />
        </div>
      </div>
    </div>
    <div :class="windowWidth?'demo_link':'demo_linkMini'" @mouseover="over_hover()" @mouseout="out_hover()" >
      <router-link :to="'/zh/ai-demos/faceRestoration'">
      <p class="toDemo">去体验</p>
      <img v-show="!demo_hover" src="../../../assets/img/Group 1489.png" alt="" />
      <img v-show="demo_hover" src="../../../assets/img/Group 1496.png" alt="" title="demo体验" />
      </router-link>
    </div>
    <div class="wrap_mini" v-if="windowWidth == false">
      <div class="introduce">
        <p class="title">
          <span class="center">关于ARC</span>
        </p>
        <p class="content">
          <span
            >腾讯ARC Lab被称为腾讯 PCG
            的「侦察兵」、「特种兵」，站在腾讯探索挑战智能媒体相关前沿技术的第一线</span
          >
        </p>
      </div>
      <div class="hope">
        <div class="hope_right">
          <img src="../../../assets/img/hope_right.png" alt="" />
        </div>
        <div class="hope_left">
          <p class="title">
            <span>使命愿景</span>
          </p>
          <p class="content">
            <span
              >ARC（Applied Research
              Center）肩负着探索和挑战智能媒体相关前沿技术的使命，旨在成为世界一流应用研究中心和行业的标杆。所属的腾讯PCG是一个集社交、流量和内容平台于一体的大型事业群，业务需求覆盖了人工智能和大数据方面几乎所有的技术。ARC因此在研究领域的广度、深度和科研产业一体化方面有着得天独厚的优势。</span
            >
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
      <div class="team">
        <div class="team_left">
          <img src="../../../assets/img/team_left.png" alt="" />
        </div>
        <div class="team_right">
          <p class="title">
            <span>团队介绍</span>
          </p>
          <p class="content">
            <span
              >自2019年初成立以来，ARC已吸引了一批高端人才加入。主要成员均来自国内外一流高校、有过国际大公司经历、发表过顶级学术文章、并持有多项国际专利。通过高校合作、假期实习、犀牛鸟计划等渠道，ARC也汇聚了一批来自国内外的优秀实习生。在人才招聘和培养方面，ARC的宗旨是找最好的人，做顶天（世界领先）立地（应用落地）的科研。
            </span>
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
      <div class="field">
        <div class="field_right">
          <img src="../../../assets/img/field_right.png" alt="" />
        </div>
        <div class="field_left">
          <p class="title">
            <span>机制和领域</span>
          </p>
          <p class="content">
            <span
              >ARC通过和业务团队的协作，形成了一套解耦业务需求和科研的机制，既减少了沟通成本又提高了技术落地的保障和效率。ARC项目涉及的技术以视觉、音频和自然语言处理为主，在音视频内容的生成、增强、检索和理解等方向发力，通过AutoML、多模态和深度学习等方法提高算法性能。项目导向兼顾业务需求和行业趋势，鼓励在技术上不断探索、创新和突破。</span
            >
          </p>
          <p class="line">
            <span></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
  },
  data() {
    return {
      bannerHeight: 600,
      windowWidth: true,
      demo_hover:false
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
    over_hover(){
      this.demo_hover = true;
    },
    out_hover(){
      this.demo_hover = false;
    },
  },
};
</script>

<style lang="less" scoped>
.index {
  width: 100%;
  font-size: 0.16rem;
  position: relative;
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    .introduce {
      margin-top: 0.6rem;
      .title {
        height: 0.5rem;
        line-height: 0.5rem;
        text-align: center;
        margin-bottom: 0.15rem;
        .center {
          position: relative;
          display: inline-block;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222;
        }
      }
      .content {
        text-align: center;
        span {
          display: inline-block;
          width: 5.71rem;
          min-height: 0.64rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
          text-align: center;
        }
      }
    }
    // 模块共用样式
    .hope,
    .team,
    .field {
      display: inline-block;
      margin-top: 0.6rem;
      margin-left: 0.45rem;
    }
    .hope_left,
    .hope_right,
    .team_left,
    .team_right,
    .field_left,
    .field_right {
      float: left;
    }
    .hope_right,
    .team_left,
    .field_right {
      width: 4.2rem;
      height: 4.2rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .hope_left,
    .team_right,
    .field_left {
      width: 5.9rem;
      .title {
        margin-top: 0.81rem;
        span {
          display: inline-block;
          height: 0.5rem;
          line-height: 0.5rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222222;
        }
      }
      .content {
        margin-top: 0.15rem;
        span {
          display: inline-block;
          width: 5.9rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          line-height: 0.32rem;
        }
      }
      .line {
        margin-top: 0.4rem;
        span {
          display: inline-block;
          width: 0.5rem;
          height: 0.02rem;
          background: #000000;
        }
      }
    }
    // 模块独有样式
    .hope_left,
    .team_left,
    .field_left {
      margin-right: 1rem;
    }
    .field {
      margin-bottom: 0.6rem;
    }
  }
  .demo_link,.demo_linkMini {
    width: 1.2rem;
    height: 1.2rem;
    display: flex;
    position: fixed;
    right: 0.08rem;
    top: 5.41rem;
    z-index: 1000;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }
    .toDemo{
      width: 100%;
      height: 0.22rem;
      position: absolute;
      top: .6rem;
      left: 0;
      color: #FFF;
      font-size: 0.16rem;
      line-height: 0.22rem;
      font-family: PingFang SC;
      font-weight: 500;
      text-align: center;
    }
  }
  .demo_linkMini{
    z-index: 101;
    top: 6.6rem;
  }
  .wrap_mini {
    .introduce {
      margin-top: 0.8rem;
      .title {
        line-height: 0.67rem;
        font-family: PingFangSC-Semibold, PingFangSC-Semibold, sans-serif;
        font-size: 0.48rem;
        color: #222222;
        text-align: center;
      }
      .content {
        text-align: center;
        margin-top: 0.22rem;
        span {
          display: inline-block;
          width: 5.67rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          color: #666666;
          text-align: center;
          line-height: 0.52rem;
        }
      }
    }
    .hope,
    .team,
    .field {
      margin: 1rem 0.69rem 0 0.91rem;
      .hope_right,
      .team_left,
      .field_right {
        width: 5.9rem;
        height: 5.9rem;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .hope_left,
      .team_right,
      .field_left {
        width: 5.9rem;
        margin: 0 auto;
        .title {
          margin: 0.6rem 0 0.4rem;
          span {
            line-height: 0.67rem;
            font-family: PingFangSC-Semibold, sans-serif;
            font-size: 0.48rem;
            color: #222222;
            text-align: center;
          }
        }
        .content {
          width: 5.9rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          color: #666666;
          line-height: 0.52rem;
        }
      }
    }
    .field {
      margin-bottom: 1rem;
    }
  }
}
</style>